<template>
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.2 0H0.8C0.3575 0 0 0.3575 0 0.8V19.2C0 19.6425 0.3575 20 0.8 20H19.2C19.6425
        20 20 19.6425 20 19.2V0.8C20 0.3575 19.6425 0 19.2 0ZM6.4 15.8C6.4 15.91 6.31 16
        6.2 16H4.2C4.09 16 4 15.91 4 15.8V4.2C4 4.09 4.09 4 4.2 4H6.2C6.31 4 6.4 4.09
        6.4 4.2V15.8ZM11.2 8.8C11.2 8.91 11.11 9 11 9H9C8.89 9 8.8 8.91 8.8 8.8V4.2C8.8
        4.09 8.89 4 9 4H11C11.11 4 11.2 4.09 11.2 4.2V8.8ZM16 10.6C16 10.71 15.91 10.8
        15.8 10.8H13.8C13.69 10.8 13.6 10.71 13.6 10.6V4.2C13.6 4.09 13.69 4 13.8
        4H15.8C15.91 4 16 4.09 16 4.2V10.6Z"
      class="icon-wrapper__template"
      :fill="color" />
  </svg>
</template>
<script>
import ProjectIconTemplate from '@/mixins/ProjectIconTemplate';
export default {
  mixins: [ProjectIconTemplate],
};
</script>