import { render, staticRenderFns } from "./Icon_template_953ce7ad-2d94-419d-9932-b7c5c1035b0c.vue?vue&type=template&id=aa354402&"
import script from "./Icon_template_953ce7ad-2d94-419d-9932-b7c5c1035b0c.vue?vue&type=script&lang=js&"
export * from "./Icon_template_953ce7ad-2d94-419d-9932-b7c5c1035b0c.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports